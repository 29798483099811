import React from 'react';
import './styles/App.scss';
import Home from './components/Home/Home';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { Route, Routes } from 'react-router-dom';
import Legal from './components/Legal/Legal';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/legal' element={<Legal />} />
      </Routes>
    </div>
  );
}

export default App;
