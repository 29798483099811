import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

export default function Header() {
  const Logo = require("../../images/logo/Logo_White.png");

  const [showFloatingLogo, setShowFloatingLogo] = useState(false);

  useEffect(() => {
    const onScroll = (e: Event) => {
      if (document.documentElement.scrollTop >= 305)
        setShowFloatingLogo(true);
      else
        setShowFloatingLogo(false);
    };
    const scroll = window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div className='Header'>
      {showFloatingLogo &&
        <button className='floating-logo' onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        }}>
          <img className='logo' src={Logo} alt="Logo" /></button>}
      <Link to="/">
        <img className='logo' src={Logo} alt="Logo" />
      </Link>
    </div>
  )
}
