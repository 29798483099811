import React from 'react'

export default function Legal() {
  return (
    <div className='Legal'>
      <h1>Legal Notice</h1>

      <h2>Site editor</h2>
      <p>This website is published by Eugène Villotte.</p>
      <p>If you have any questions or require information, please contact me at the following e-mail address: eugene@villotte.com.</p>

      <h2>Hosting</h2>
      <p>This website is hosted by:</p>
      <p>O2switch</p>

      <h2>Personal data</h2>
      <p>This site does not collect personal data without the explicit consent of users. No personal data is used for commercial purposes.</p>
    </div>
  )
}
