import React from 'react'
import { Link } from 'react-router-dom';

export default function Footer() {
  const text = "Brought to you with ♥";
  const textArray = text.split("");

  return (
    <footer className='Footer'>
      <div className="credits">
        {/* <p>— Terminal Grotesque by Raphaël Bastide, with the contribution of Jérémy Landes. Distributed by velvetyne.fr.</p> */}
        <p>— Geist font by Vercel.</p>
        <p>—— Pilowlava by Anton Moglia, Jérémy Landes, with the contribution of Maksym Kobuzan. Distributed by velvetyne.fr.</p>
        <p>——— Nohemi Typeface by Rajesh Rajput.</p>
        <p>———— Icons by FontAwesome.</p>
        <p>————— Illustrations by unDraw.</p>
      </div>
      <p className='pilowlava'>
        {textArray.map((c, i) =>
          <span className={c === ' ' ? 'space' : ''} key={i} style={{
            animationDelay: i / 20 + "s"
          }}>{c}</span>)}
      </p>
      <p className='copyright'>&copy; Eugène Villotte 2024. All rights reserved — Tous droits réservés.</p>
      <p className='legal'><Link to="/legal"><i className="fa-solid fa-caret-right"></i> Legal Notice</Link></p>
    </footer>
  )
}
