import React, { useEffect, useRef, useState } from 'react'
import Marquee from 'react-fast-marquee';
import Footer from '../Footer/Footer';

export default function Home() {
  const StarImage = require("../../images/picto/Picto_Star_White_256.png");
  const SpiralImage = require("../../images/picto/Picto_Spiral_White_256.png");
  const Logo = require("../../images/logo/Logo_White.png");
  const DiploLogo = require("../../images/diplo_banner_dark_512.png");
  const SoundRoomLogo = require("../../images/soundroom.png");
  const [diploCardsOffset, setDiploCardsOffset] = useState(0);
  const [projectCardsOffsets, setProjectCardsOffsets] = useState([]) as [number[], Function];
  const [newBackgroundScale, setNewBackgroundScale] = useState(0);
  const [newBackgroundStepScale, setNewBackgroundStepScale] = useState(0);
  const [isNewBackgroundSet, setIsNewBackgroundSet] = useState(false);
  const [highlightedOpacity, setHighlightedOpacity] = useState(0);

  const diploProject = useRef<HTMLDivElement>(null);
  const projectCards = useRef<HTMLDivElement[]>([]);
  const scrollZoom = useRef<HTMLElement>(null);
  const scrollZoom2 = useRef<HTMLElement>(null);

  useEffect(() => {
    const onScroll = (e: Event) => {
      const diplo = diploProject.current;
      if (diplo) {
        const offset: number = Math.max(
          (
            diplo.getBoundingClientRect().y
            - window.innerHeight / 3
            + 0),
          0
        )
          / 8;
        setDiploCardsOffset(offset);
      }

      projectCards.current.forEach((ref, i) => {

        if (ref) {
          const offset: number = Math.max(
            (
              ref.getBoundingClientRect().y
              - window.innerHeight / 3
              + 0),
            0
          )
            / 8;
          const temp = projectCardsOffsets;
          temp[i] = offset;

          setProjectCardsOffsets([...temp]);
          // setRefCardsOffset(offset);
        }
      });

      const zoom = scrollZoom.current;
      const zoom2 = scrollZoom2.current;
      if (zoom && zoom2) {
        const scale: number =
          Math.max(
            Math.min(
              -zoom.getBoundingClientRect().y,
              // -zoom2.getBoundingClientRect().y,
              window.innerHeight
            ),
            0
          )
          / window.innerHeight * Math.max(window.innerWidth, window.innerHeight) * 1.5;
        setNewBackgroundScale(scale);


        const scaleStep: number =
          Math.max(
            Math.min(
              -zoom.getBoundingClientRect().y,
              window.innerHeight
            ),
            0
          )
          / window.innerHeight * Math.max(window.innerWidth * 0.5, window.innerHeight * 0.5);
        setNewBackgroundStepScale(scaleStep);
        if (zoom2.getBoundingClientRect().y < -window.innerHeight)
          setIsNewBackgroundSet(true);
        else
          setIsNewBackgroundSet(false);
        // console.log(zoom2.getBoundingClientRect().y);


        // if (scale < scaleStep && scale > 0) {
        const opacity = Math.max(-Math.abs(scale / scaleStep - .5) + .5, 0) * 2;
        setHighlightedOpacity(opacity);
        // }

      }
    };

    window.scrollTo({ top: 0 });

    const scroll = window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div className='Home'>
      <section className="hero">
        <div className="title-container">
          <h1 className='title1'>Eugène Villotte</h1>
          {/* <h1 className='title2'>Eugène Villotte</h1> */}

          <div className="description">
            <p>
              <i className="fa-solid fa-frog"></i>
              Passionate 18 yo full-stack and software developer
            </p>
          </div>
        </div>


        <div className="marquees">
          <Marquee className='marquee'>
            <div className="marquee-content">
              Web creation <img src={StarImage} alt="Star" className='star' />
              Web design <img src={StarImage} alt="Star" className='star' />
              Software programming <img src={StarImage} alt="Star" className='star last' />
            </div>
          </Marquee>
          <Marquee direction='right' className='marquee'>
            <div className="marquee-content">
              Programmation logicielle <img src={SpiralImage} alt="Star" className='star' />
              Conception Web <img src={SpiralImage} alt="Star" className='star' />
              Création Web <img src={SpiralImage} alt="Star" className='star last' />
            </div>
          </Marquee>
        </div>
      </section>
      {/* <button><img src={SpiralImage} alt="Star" className='star' /></button> */}

      <section className="projects-title">
        <h2
          style={{
            fontSize: 3.5 - projectCardsOffsets[0] / 80 + "rem",
            // filter: "blur(" + (projectCardsOffsets[0] - 100) / 1 + "px)",
          }}
        >My projects</h2>
      </section>

      <section className="projects">
        <div className="project" ref={el => { if (el) projectCards.current[0] = el }}>
          <div className="card-code" style={{ translate: "-" + projectCardsOffsets[0] + "px 0" }}>
            <code>
              InsertL 72, 101, 108, 108, 111, 32, 87, 111, 114, 108, 100, 33<br />
              <br />
              Label PrintLoop<br />
              Out<br />
              Pointer +<br />
              Comp $value, 0<br />
              JumpNotEq PrintLoop<br />
              Exit 0
            </code>
          </div>
          <div className="card-main" style={{ translate: projectCardsOffsets[0] + "px 0" }}>
            <span className="tag">PROG</span>
            <div className="text">
              <h3>Diplo Programming Language</h3>
              <p>A turing complete, imperative, and very useless programming language, developped in C.</p>
            </div>
            <div className='flex'>
              <img src={DiploLogo} alt="Diplo Logo" />
              <div className="button-container">
                <a href='https://github.com/TheBoloss/DiploInterpreter' target='blank'><i className="fa-solid fa-caret-right"></i> View on GitHub</a>
              </div>
            </div>
          </div>
        </div>

        <div className="project blue reverse" ref={el => { if (el) projectCards.current[1] = el }}>
          <div className="card-code" style={{ translate: "-" + projectCardsOffsets[1] + "px 0" }}>
            <code>
              // send sound<br />
              // send message to everyone
            </code>
          </div>
          <div className="card-main" style={{ translate: projectCardsOffsets[1] + "px 0" }}>
            <span className="tag">WEB</span>
            <div className="text">
              <h3>SoundRoom Chat Platform</h3>
              <p>Multi-user live chat platform with synchronized soundboard.</p>
            </div>
            <div className="flex">
              <img src="undraw_undraw_chatting_lt27_d787.svg" alt="Chat" />
              <div className="button-container">
                <a href='https://room.vpla.net' target='blank'><i className="fa-solid fa-caret-right"></i> Open</a>
              </div>
            </div>
          </div>
        </div>

        <div className="project red" ref={el => { if (el) projectCards.current[2] = el }}>
          <div className="card-code" style={{ translate: "-" + projectCardsOffsets[2] + "px 0" }}>
            <code>
              // Portfolio<br />
            // 포트폴리오<br />
            // Χαρτοφυλάκιο<br />
            // Портфель<br />
            //مَلَفّ<br />
            // Cartera<br />
            // 文件夹
            </code>
          </div>
          <div className="card-main" style={{ translate: projectCardsOffsets[2] + "px 0" }}>
            <span className="tag">WEB</span>
            <div className="text">
              <h3>MetsTonPull</h3>
              <p>Artist portfolio for Gaëlle Villotte.</p>
            </div>
            <div className="flex">
              <img src="undraw_flowers_vx06.svg" alt="Flower" />
              <div className="button-container">
                <a href='https://metstonpull.com' target='blank'><i className="fa-solid fa-caret-right"></i> Open</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="other-projects">
        <h2>Other projects</h2>
        <Marquee pauseOnHover pauseOnClick className="box">
          <div className="project">
            <h3>
              <i className="fa-solid fa-cloud"></i>
              Weather
              <span className="tag">WEB</span>
            </h3>
            <a href="https://weather.vpla.net" target='blank' className='red'>
              <i className="fa-solid fa-caret-right"></i>
              Open
            </a>
          </div>
          {/* <div className="project">
            <h3>Weather <span className="tag">WEB</span></h3>
            <a href="" target='blank'>Open</a>
          </div> */}
        </Marquee>
      </section>

      <section className="scroll-zoom" ref={scrollZoom}>
        {/* <img src="Circle.svg"
          className="new-background"
          alt="Background"
          style={{
            width: newBackgroundStepScale + "px",
            rotate: -newBackgroundStepScale / 100 + "deg",
            display: newBackgroundScale > newBackgroundStepScale ? "none" : "initial"
          }} /> */}
        <img src="Circle.svg"
          className="new-background"
          alt="Background"
          style={{
            width: newBackgroundScale + "px",
            rotate: -newBackgroundScale / 100 + "deg",
          }} />
        {/* <div className='highlighted' style={{
          opacity: highlightedOpacity,
          display: highlightedOpacity > .25 ? "initial" : "none"
        }}>
          <h2>Lorem ipsum dolor sit amet consectetur adipisicing.</h2>
        </div> */}
      </section>
      <section className="scroll-zoom" ref={scrollZoom2}></section>
      {/* <section className="scroll-zoom"></section> */}

      <Footer />
    </div>
  )
}
